import React from 'react';
import { shape, node, string, arrayOf, bool } from 'prop-types';
import dynamic from 'next/dynamic';
import Meta from '../Meta';

const DynamicHeader = dynamic(() => import('../Header'));
const DynamicBanner = dynamic(() => import('../Banner'));
const DynamicFooter = dynamic(() => import('../Footer'));

const Layout = ({ seo, metadata, header, banner, footer, children, updatedBy }) => (
  <>
    <Meta seo={seo} metadata={metadata} updatedBy={updatedBy.name} />
    {header && <DynamicHeader header={header} />}
    {banner && <DynamicBanner {...banner} />}
    {children}
    {footer && <DynamicFooter footer={footer} />}
  </>
);

Layout.propTypes = {
  seo: shape({
    description: string,
    noIndex: bool,
    noFollow: bool,
    metaImage: shape({
      id: string,
      url: string,
    }),
  }),
  metadata: shape({ tags: arrayOf(shape({})) }),
  header: shape({}),
  banner: shape({}),
  footer: shape({}),
  updatedBy: shape({
    name: string,
  }),
  children: node.isRequired,
};

Layout.defaultProps = {
  seo: {
    title: '',
    description: '',
    canonical: '',
    noIndex: false,
    noFollow: false,
    metaImage: {
      id: '',
      url: '',
    },
  },
  metadata: null,
  header: null,
  footer: null,
  banner: null,
  updatedBy: {
    name: null,
  },
};

export default Layout;
